import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { useLocalization } from "../../../../hooks/useLocalization";
import useAnalytics from "../../../../hooks/useAnalytics";
import { EventLogs } from "../../../../components/Constants";

/**
 * Konuşma laboratuvarı kartlarında görüntülenecek bilgilendirici emoji listesi.
 * Her kart rastgele bir emoji seçer.
*/
const INFO_EMOJIS = [
    '💡', '🔍', '✨', '🎯', '🗣️', '📚',
];

const SpeakingLabCard = ({ isFirstSequence, onNext, cardData }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const [countdown, setCountdown] = useState(10);
    const [selectedEmoji] = useState(() => {
        return INFO_EMOJIS[Math.floor(Math.random() * INFO_EMOJIS.length)];
    });
    

    const handleNext = useCallback(() => {
        if (countdown === 0) {
            onNext();
        }
    }, [countdown, onNext]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            handleNext();
        }
    }, [countdown, handleNext]);

    useEffect(() => {
        analytics.useAnalytics(
            EventLogs.SPEAKING_LAB_INFO_CARD_VIEWED,
            undefined,
            false,
            true
        );
    }, []);

    return (
        <>
            <div className="bg-white rounded-2xl border-2 border-blue-100 shadow-md min-h-[300px] mt-5 flex flex-col items-center justify-between p-6">
                <div className="relative">
                    <div className="w-16 h-16 bg-blue-50 rounded-full flex items-center justify-center">
                        <motion.div
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            transition={{
                                repeat: Infinity,
                                repeatType: "reverse",
                                duration: 1.8,
                                ease: "easeInOut"
                            }}
                            style={{
                                willChange: "transform",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                lineHeight: 1
                            }}
                            className="text-4xl transform-gpu"
                        >
                            {selectedEmoji}
                        </motion.div>
                    </div>
                </div>

                <div className="flex flex-col items-center space-y-3 my-6 px-4 w-full max-w-xl">
                    <h2 className="text-lg font-bold text-center">
                        {cardData.content}
                    </h2>
                    <p className="text-center">
                        {cardData.contentTranslation}
                    </p>
                </div>

                <div className="bg-blue-50 rounded-lg p-3 px-4 w-full max-w-xl">
                    <p className="text-center text-sm">
                        {isFirstSequence
                            ? strings.speaking_lesson.speaking_lab.first_card_counter_text.replace("#second#", countdown)
                            : strings.speaking_lesson.speaking_lab.second_card_counter_text.replace("#second#", countdown)
                        }
                    </p>
                </div>
            </div>

            <div className="btn-outer-div mt-5">
                <button 
                    className="button primary-button w-full"
                    onClick={() => {
                        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
                            source: "info_card"
                        });
                        onNext();
                    }}
                >
                    {strings.speaking_lesson.quiz.continue}
                </button>
            </div>
        </>
    );
}

export default SpeakingLabCard;