import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { Schedule_Calendar } from '../../../assets/img';
import { Loading, ModalContent, ModalOverlay } from '../../../components';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetAttendedLessonCountByDate, GetMemberYearlySummary } from '../../../services/Dashboard/DashboardService';
import { FirebaseContext } from '../../../store/FirebaseProvider';
import { closeModal, openModal } from '../../../utils';
import useAnalytics from '../../../hooks/useAnalytics';
import { EventLogs } from '../../../components/Constants';

const YearInReviewCard = ({ memberId }) => {

  const yearInReviewCard = useContext(FirebaseContext)?.yearInReviewCard;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if(yearInReviewCard?.isVisible) {
      GetAttendedLessonCountByDate(memberId, yearInReviewCard?.startDate, yearInReviewCard?.endDate)
        .then(result => {
          if(result.status === 200){
            const resultContent = JSON.parse(result.content);
            setIsVisible(resultContent > yearInReviewCard?.attendedLessonCountLimit);
          }
        });
    }
  }, [yearInReviewCard]);

  return (
    isVisible && 
      <YearInReviewCardContent 
        date={yearInReviewCard?.endDate?.split('-')[0]} 
        startDate={yearInReviewCard?.startDate}
        endDate={yearInReviewCard?.endDate}
        memberId={memberId}
      />
  )
};

const YearInReviewCardContent = ({ date, startDate, endDate, memberId }) => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const controls = useAnimation();
  const contentRef = useRef(null);
  const isInitialMount = useRef(true);
  const modalOverlayRef = useRef();
  const modalContentRef = useRef();

  const [isExpanded, setIsExpanded] = useState(() => {
    const hasSeenCard = localStorage.getItem('yearInReviewCardSeen');
    if (!hasSeenCard) {
      localStorage.setItem('yearInReviewCardSeen', 'true');
      return true;
    }
    return false;
  });

  const [contentWidth, setContentWidth] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  
  const [data, setData] = useState({
    attendedLessonCount: 0,
    totalMinutes: 0,
    aiSessionCount: 0,
    pronouncedWordCount: 0,
    pronouncedSentenceCount: 0
  });

  const handleDragEnd = (_, info) => {
    if (info.offset.x > 50 && !isExpanded) {
      handleToggleCard(true);
    } else if (info.offset.x < -50 && isExpanded) {
      handleToggleCard(false);
    } else {
      controls.start({
        x: isExpanded ? 0 : -contentWidth,
        transition: { type: 'spring', stiffness: 300, damping: 30 }
      });
    }
  };

  const handleToggleCard = (expand) => {
    setIsExpanded(expand);
    requestAnimationFrame(() => {
      controls.start({
        x: expand ? 0 : -contentWidth,
        transition: { type: 'spring', stiffness: 300, damping: 30 }
      });
    });
  };

  const handleCardClick = (e) => {
    e.preventDefault();
    handleToggleCard(!isExpanded);
    analytics.useAnalytics(EventLogs.YEARLY_SUMMARY_MODAL_OPENED);
    setTimeout(() => {
      setIsModalVisible(true);
      openModal(modalOverlayRef, modalContentRef);
      controls.start({
        x: -window.innerWidth,
        transition: { type: 'spring', stiffness: 300, damping: 30 }
      });
    }, 300);
  };

  const handleTabClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleCard(!isExpanded);
    analytics.useAnalytics(EventLogs.YEARLY_SUMMARY_CARD_OPENED);
  };

  const handleCloseModal = () => {
    closeModal(modalOverlayRef, modalContentRef);
    setIsModalVisible(false);
    controls.start({
      x: -contentWidth,
      transition: { type: 'spring', stiffness: 300, damping: 30 }
    });
  };

  useEffect(() => {
    if(memberId){
      setLoading(true);
      GetMemberYearlySummary(memberId, startDate, endDate)
        .then(result => {
          if(result.status === 200){
            setData({
              attendedLessonCount: result.content.totalLessonCount,
              totalMinutes: result.content.totalLessonDuration,
              aiSessionCount: result.content.aiSessionCount,
              pronouncedWordCount: result.content.wordPronunciationCount,
              pronouncedSentenceCount: result.content.sentencePronunciationCount
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [memberId]);

  useEffect(() => {
    if (contentRef.current) {
      const width = contentRef.current.getBoundingClientRect().width;
      setContentWidth(width);
      
      controls.set({ x: isExpanded ? 0 : -width });
      isInitialMount.current = false;
    }
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        setStartAnimation(true);
      }, 500);
    } else {
      setStartAnimation(false);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (startAnimation && !loading) {
      const lastMetricDelay = 0.4 * 4;
      const animationDuration = 0.8;
      const totalDelay = (lastMetricDelay + animationDuration) * 1000;

      setTimeout(() => {
        setShowConfetti(true);
      }, totalDelay);
    } else {
      setShowConfetti(false);
    }
  }, [startAnimation, loading]);

  return (
    <>
      {showConfetti && (
        <div className="fixed inset-0 z-[9999] pointer-events-none">
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            gravity={0.04}
            run={true}
            numberOfPieces={500}
            recycle={false}
            initialVelocityY={{ min: 0, max: 15 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      )}

      <motion.div
        className="fixed bottom-[168px] left-0 flex items-center z-50"
        drag="x"
        dragConstraints={{ 
          left: isModalVisible ? -window.innerWidth : -contentWidth, 
          right: 0 
        }}
        onDragEnd={handleDragEnd}
        animate={controls}
        initial={{ x: -9999 }}
      >
        <button
          type='button'
          onClick={handleCardClick}
          className="relative flex items-center focus:outline-none"
        >
          <div 
            ref={contentRef}
            className="w-[340px] h-32 p-0.5 px-0 bg-gradient-to-b from-[#4568DC] to-[#B06AB3]"
          >
            <div className="h-full w-full bg-white flex items-center p-4 pr-1">
              <div className="w-[50px] h-[50px] rounded-full flex justify-center items-center mr-2 flex-shrink-0">
                <img src={Schedule_Calendar} alt="Schedule_Calendar" />
              </div>
              <div className="flex-1 min-w-0 pr-3">
                <h3 className="font-bold text-[15px] text-black mb-1 truncate">
                  {strings.dashboard.yearly_summary.card_title?.replace('#year#', date)}
                </h3>
                <p className="text-sm text-primary underline truncate">
                  {strings.dashboard.yearly_summary.card_content}
                </p>
              </div>
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              handleTabClick(e);
            }}
            className="absolute right-0 translate-x-full top-0 h-full cursor-pointer"
          >
            <div className="w-8 h-full p-0.5 pl-0 rounded-r-lg bg-gradient-to-b from-[#4568DC] to-[#B06AB3]">
              <div className="h-full w-full bg-[#E3F2FD] rounded-r-lg flex items-center justify-center">
                <div
                  className={`transition-transform duration-300 ease-in-out w-full h-full flex items-center justify-center ${
                    isExpanded ? '-rotate-180' : 'rotate-0'
                  }`}
                >
                  <span className="material-symbols-outlined text-[32px] flex items-center justify-center -ml-0.5">
                    chevron_right
                  </span>
                </div>
              </div>
            </div>
          </div>
        </button>
      </motion.div>

      <ModalOverlay ref={modalOverlayRef} />
      <ModalContent
        ref={modalContentRef}
        overlayRef={modalOverlayRef}
        show={isModalVisible}
        showCloseButton={true}
        closeAction={handleCloseModal}
        className="sm:w-[500px] max-h-max overflow-y-auto bg-[#EBF6FF]"
      >
        {loading ? (
          <div className="min-h-[400px]">
            <Loading />
          </div>
        ) : (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                type: "spring",
                bounce: 0.3
              }}
              className="w-full py-6 overflow-hidden"
            >
              <div className="text-center mb-8">
                <h2 className="text-2xl font-bold text-base-text mb-2">
                  {strings.dashboard.yearly_summary.card_title.replace('#year#', date)}
                </h2>
                <p className="text-sm text-base-text px-4">
                  {strings.dashboard.yearly_summary.modal.content}
                </p>
              </div>

              <div className="space-y-3 px-4">
                {data.attendedLessonCount > 0 && (
                  <MetricItem
                    value={data.attendedLessonCount}
                    label={strings.dashboard.yearly_summary.modal.total_lesson_count}
                    index={0}
                    animate={startAnimation}
                  />
                )}
                {data.totalMinutes > 0 && (
                  <MetricItem
                    value={data.totalMinutes}
                    label={strings.dashboard.yearly_summary.modal.total_minute}
                    index={1}
                    animate={startAnimation}
                  />
                )}
                {data.aiSessionCount > 0 && (
                  <MetricItem
                    value={data.aiSessionCount}
                    label={strings.dashboard.yearly_summary.modal.ai_session}
                    index={2}
                    animate={startAnimation}
                  />
                )}
                {data.pronouncedWordCount > 0 && (
                  <MetricItem
                    value={data.pronouncedWordCount}
                    label={strings.dashboard.yearly_summary.modal.total_word}
                    index={3}
                    animate={startAnimation}
                  />
                )}
                {data.pronouncedSentenceCount > 0 && (
                  <MetricItem
                    value={data.pronouncedSentenceCount}
                    label={strings.dashboard.yearly_summary.modal.total_sentence}
                    index={4}
                    animate={startAnimation}
                  />
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </ModalContent>
    </>
  );
};

const MetricItem = ({ value, label, index, animate }) => {
  return (
    <motion.div
      initial={{ x: -window.innerWidth }}
      animate={animate ? { x: 0 } : {}}
      transition={{
        type: "spring",
        delay: index * 0.4,
        duration: 0.8,
        bounce: 0.3
      }}
      className="flex items-center justify-between bg-white p-5 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100"
    >
      <div className="flex flex-col items-center flex-1">
        <span className="text-3xl font-bold text-primary">
          {value}
        </span>
      </div>
      <div className="flex-[2] ml-6">
        <span className="text-sm text-base-text font-medium">{label}</span>
      </div>
    </motion.div>
  );
};

export default YearInReviewCard; 