import classNames from 'classnames';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Lottie from "lottie-react";
import { Loading, SentenceBookmarkButton } from '../../../../components';
import { EventLogs, PronunciationErrors, PronunciationResultType } from '../../../../components/Constants';
import useAuth from '../../../../hooks/useAuth';
import { useLocalization } from "../../../../hooks/useLocalization";
import useSpeechStudio from '../../../../hooks/useSpeechStudio';
import useWindowSize from '../../../../hooks/useWindowSize';
import { removePunctuation } from '../../../../utils';
import { ScoreCard } from '../../../Exercises/components';
import { SpeakingLabCorrect, SpeakingLabDragDropSentence, SpeakingLabPlaybackOptions, SpeakingLabWrong } from './components';
import useAnalytics from '../../../../hooks/useAnalytics';

const SpeakingLabCreateSentence = ({ exerciseData, onNext, speakSentence, isSpeakingComplete, dynamicPassingScore, ttsInfo, defaultOptions, isLastExercise }) => {
    
    const strings = useLocalization();
    const { member } = useAuth();
    const {
        handleSpeechStudioRecording,
        handleResetStates,
        handleResetIsFirstRecordingCompleted,
        showAnimation,
        isFirstRecordingCompleted,
        speechStudioLoading,
        speechStudioResult,
        isErrorOccured,
        audioKey
    } = useSpeechStudio({
        contentId: exerciseData?.exercise?.sentenceId,
        memberId: member?.MemberId,
        source: PronunciationResultType.SPEAKING_LAB
    });
    const windowSize = useWindowSize();
    const analytics = useAnalytics();

    const isMobile = windowSize?.width < 640;

    // Drag&Drop ile oluşturulan cümle burada tutuluyor
    const [currentSentence, setCurrentSentence] = useState("");
    // Drag&Drop ile oluşturulan cümle doğru mu kontrol ediliyor
    const [isDraggedSentenceCorrect, setIsDraggedSentenceCorrect] = useState(null);
    // Pronunciation ile oluşturulan cümle doğru mu kontrol ediliyor
    const [isPronunciationCorrect, setIsPronunciationCorrect] = useState(null);
    // Telaffuz etme alanına geçilip geçilmediğini tutacak state
    const [showPronunciationExercise, setShowPronunciationExercise] = useState(false);
    // Cümle doğru olduktan sonra 
    const [showAvailableList, setShowAvailableList] = useState(true);
    // Cümle sırasının değiştirilebilir olup olmadığını tutacak state
    const [isDraggable, setIsDraggable] = useState(true);
    // Cümle sıfırlama durumlarını kontrol etmek için kullanılan state
    const [resetTrigger, setResetTrigger] = useState(0);
    // Yanlış cevap sayısını tutan yeni state
    const [wrongAttempts, setWrongAttempts] = useState(0);
    // Sürükle-bırak aşaması için yanlış deneme sayısı
    const [dragDropWrongAttempts, setDragDropWrongAttempts] = useState(0);
    // Seçili kelime sayısını kontrol etmek için yeni state
    const [hasSelectedWords, setHasSelectedWords] = useState(false);
    // Tooltip için state'ler
    const [showRecordingTooltip, setShowRecordingTooltip] = useState(false);
    // Doğru cümleyi göstermek için state
    const [showCorrectSentence, setShowCorrectSentence] = useState(false);
    // Kayıt zamanlayıcısı için ref
    const recordingTimerRef = useRef(null);
    // Kayıt kontrolü için ref
    const isRecordingRef = useRef(false);
    

    const startRecordingWithTimeout = useCallback((sentence) => {
        isRecordingRef.current = true;
        handleSpeechStudioRecording(removePunctuation(sentence), member?.MemberId);
        handleResetStates();

        // Önceki zamanlayıcıyı temizle
        if (recordingTimerRef.current) {
            clearTimeout(recordingTimerRef.current);
        }

        // 15 saniye sonra tooltip'i göster ve kaydı durdur
        recordingTimerRef.current = setTimeout(() => {
            if (isRecordingRef.current) {
                setShowRecordingTooltip(true);
                isRecordingRef.current = false;
                handleResetStates();
                // MediaRecorder'ı durdur
                if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                    window.mediaRecorder.stop();
                }
                // Mikrofon erişimini kapat
                if (window.mediaStream && window.mediaStream.getTracks) {
                    window.mediaStream.getTracks().forEach(track => track.stop());
                }
            }
        }, 15000);
    }, [member?.MemberId, handleSpeechStudioRecording, handleResetStates]);

    // Drag&Drop ile cümle değiştirildiğinde, alt componentten buraya cümle gönderiliyor
    const handleSentenceChange = useCallback((newSentence) => {
        setCurrentSentence(newSentence);
        // Cümlede en az bir kelime var mı kontrol et
        setHasSelectedWords(newSentence.trim().length > 0);
    }, []);

    // Kontrole et butonuna tıklandığında Drag&Drop ile cümle doğru mu kontrol ediliyor
    const checkSentence = useCallback(() => {
        const normalizeString = (str) => removePunctuation(str.toLowerCase().trim());
        
        const userSentence = normalizeString(currentSentence);
        const correctSentence = normalizeString(exerciseData?.exercise?.sentence);
        
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });

        const isCorrect = userSentence === correctSentence;
        setIsDraggedSentenceCorrect(isCorrect);
        setShowCorrectSentence(false);

        // Yanlış ise deneme sayısını artır
        if (!isCorrect) {
            setDragDropWrongAttempts(prev => prev + 1);
        }
    }, [currentSentence, exerciseData?.exercise?.sentence]);

    // Drag and Drop componentine cümleyi sıfırlamak için kullanılıyor
    const handleReset = () => {
        // Yanlış cevap durumunda doğru cümleyi göster
        if (!isDraggedSentenceCorrect) {
            setCurrentSentence(exerciseData?.exercise?.sentence);
            setIsDraggable(true);
            setShowAvailableList(true);
            setShowCorrectSentence(true);
        } else {
            setResetTrigger(prev => prev + 1);
            setCurrentSentence("");
            setIsDraggable(true);
            setShowAvailableList(true);
            setShowCorrectSentence(false);
        }
        setIsDraggedSentenceCorrect(null);
        window.scrollTo(0, 0);
    };

    // handleStartPronunciationExercise fonksiyonunu güncelle
    const handleStartPronunciationExercise = useCallback((sentence) => {
        setShowAvailableList(false);
        setShowPronunciationExercise(true);
        setCurrentSentence(sentence);
        window.scrollTo(0, 0);
        startRecordingWithTimeout(sentence);
    }, [startRecordingWithTimeout]);

    // handlePronunciation fonksiyonunu güncelle
    const handlePronunciation = async (sentence) => {
        window.scrollTo(0, 0);
        setIsDraggedSentenceCorrect(null);
        setIsPronunciationCorrect(null);
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_PRONOUNCE_CLICKED);
        startRecordingWithTimeout(sentence);
    };

    // Cümleyi tekrar seslendirmek için kullanılır.
    const handleTryAgain = async () => {
        handleResetIsFirstRecordingCompleted();
        handleResetStates();
        setIsDraggedSentenceCorrect(null);
        setIsPronunciationCorrect(null);
        window.scrollTo(0, 0);
        await speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);

        analytics.useAnalytics(EventLogs.SPEAKING_LAB_TRY_AGAIN_CLICKED,{
            source: "drag_and_drop"
        });
    }

    const handleContinue = () => {
        onNext();
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CONTINUE_CLICKED, {
            source: "drag_and_drop"
        });
    }

    // Ses kaydını durdurmak için yardımcı fonksiyon
    const stopRecording = () => {
        if (isRecordingRef.current) {
            isRecordingRef.current = false;
            handleResetStates();
            // MediaRecorder'ı durdur
            if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                window.mediaRecorder.stop();
            }
            // Mikrofon erişimini kapat
            if (window.mediaStream && window.mediaStream.getTracks) {
                window.mediaStream.getTracks().forEach(track => track.stop());
            }
        }
    };

    // Doğru / Yanlış cevapların kontrolü ekleniyor.
    const renderResult = () => {

        if(isErrorOccured)
            return <SpeakingLabWrong textInfoTitle={strings.speaking_lesson.speaking_lab.pronunce_error_text} buttonClick={handleTryAgain} />;

        if(!showPronunciationExercise && isDraggedSentenceCorrect !== null) {
            if (!isDraggedSentenceCorrect) 
                return (
                    <SpeakingLabWrong 
                        buttonClick={handleReset} 
                        textInfoTitle={strings.speaking_lesson.speaking_lab.drag_drop_wrong_message}
                        skipButtonCondition={dragDropWrongAttempts >= 3}
                        skipButtonClick={() => {
                            onNext();
                            analytics.useAnalytics(EventLogs.SPEAKING_LAB_SKIP_CLICKED, {
                                source: "drag_and_drop"
                            });
                        }}
                    />
                );
            else
                return (
                    <SpeakingLabCorrect 
                        buttonClick={() => handleStartPronunciationExercise(exerciseData?.exercise?.sentence)} 
                        textInfoTitle={strings.speaking_lesson.speaking_lab.drag_drop_correct_message} 
                        buttonText={strings.video_practice.word_card.word_pronunciation} 
                    />
                );
        }

        if(showPronunciationExercise && isPronunciationCorrect !== null) {
            if(!isPronunciationCorrect)
                return (
                    <SpeakingLabWrong 
                        textInfo={speechStudioResult?.words} 
                        buttonClick={handleTryAgain} 
                        skipButtonCondition={wrongAttempts >= 3} 
                        skipButtonClick={onNext} 
                    />
                );
            else
                return (
                    <SpeakingLabCorrect 
                        textInfo={speechStudioResult?.words} 
                        buttonClick={handleContinue}
                        buttonText={isLastExercise ? strings.video_practice.complete : strings.market.information.button}
                    />
                );
        }
    };

    // Kullanıcı cümle sırasını tamamladıysa isDraggable state'ini false yapıyor.
    // Bu sayede kullanıcı cümle sırasını değiştiremez.
    useEffect(() => {
        if(isDraggedSentenceCorrect !== null)
            setIsDraggable(false);
    }, [isDraggedSentenceCorrect]);

    // Sayfaya ilk render edildiğinde, cümleyi seslendirir.
    useEffect(() => {
        if(Object.keys(ttsInfo).length > 0)
            speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
    }, []);

    /*
        Bu kod, `speechStudioResult` ve `speechStudioLoading` değişkenlerini izler.
        Eğer `speechStudioResult` varsa ve `speechStudioLoading` false ise
        `isCorrect` değişkenini `speechStudioResult.Score` değişkeni ile `dynamicPassingScore` değişkenini karşılaştırarak günceller.
    */
    useEffect(() => {   
        const playFeedbackSound = async (isCorrect) => {
            isCorrect
                ? await speakSentence('', '', 'correct-answer')
                : await speakSentence('', '', 'wrong-answer');
        };

        if(speechStudioResult && !speechStudioLoading) {
            const passedScore = speechStudioResult.pron_score >= dynamicPassingScore;
            const hasErrors = speechStudioResult.words.some(word => {
                return word.error_type === PronunciationErrors.MISPRONUNCIATION || 
                        word.error_type === PronunciationErrors.OMISSION;
            });
    
            const isCorrect = !hasErrors && passedScore;
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
            playFeedbackSound(isCorrect);        
            setIsPronunciationCorrect(isCorrect);

            // Eğer cevap yanlışsa, wrongAttempts'i artır
            if (!isCorrect) {
                setWrongAttempts(prevAttempts => prevAttempts + 1);
            }
        }
    }, [speechStudioResult, speechStudioLoading, dynamicPassingScore]);

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_IMPRESSION);
    }, [])

    // speechStudioResult değiştiğinde kayıt durumunu güncelle
    useEffect(() => {
        if (speechStudioResult) {
            isRecordingRef.current = false;
        }
    }, [speechStudioResult]);

    // Tooltip'i 4 saniye sonra gizle
    useEffect(() => {
        let tooltipTimer;
        if (showRecordingTooltip) {
            tooltipTimer = setTimeout(() => {
                setShowRecordingTooltip(false);
            }, 4000);
        }
        return () => clearTimeout(tooltipTimer);
    }, [showRecordingTooltip]);

    // Component unmount olduğunda zamanlayıcıyı ve kaydı temizle
    useEffect(() => {
        return () => {
            if (recordingTimerRef.current) {
                clearTimeout(recordingTimerRef.current);
            }
            if (window.mediaRecorder && window.mediaRecorder.state === 'recording') {
                window.mediaRecorder.stop();
            }
            if (window.mediaStream) {
                window.mediaStream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    return (
        <>
            <p className="my-5 text-center">
                {(isDraggedSentenceCorrect === null && !showPronunciationExercise) && 
                    strings.speaking_lesson.speaking_lab.create_sentence_title
                }

                {(isDraggedSentenceCorrect !== null && !isDraggedSentenceCorrect) &&
                    strings.speaking_lesson.speaking_lab.create_sentence_wrong
                }

                {(showPronunciationExercise || isDraggedSentenceCorrect) && 
                   strings.speaking_lesson.speaking_lab.create_sentence_correct
                }
            </p>
            <div className="gray-section min-h-[300px] flex flex-col">
                <div className="w-full flex justify-end mb-5">
                    <SentenceBookmarkButton 
                        initialIsSaved={exerciseData?.exercise?.isSavedSentence} 
                        sentenceId={exerciseData?.exercise?.sentenceId} 
                        logEvent={{
                            true: EventLogs.SPEAKING_LAB_ADDED_TO_LEARNING_LIST,
                            false: EventLogs.SPEAKING_LAB_REMOVED_TO_LEARNING_LIST
                        }}
                    />
                </div>
                <div className='flex flex-col gap-2 justify-start mt-8'>
                    <SpeakingLabDragDropSentence 
                        wordsList={exerciseData?.exercise?.words} 
                        onSentenceChange={handleSentenceChange}
                        resetTrigger={resetTrigger}
                        showAvailableList={showAvailableList}
                        isDraggable={!showPronunciationExercise && isDraggable}
                        initialSentence={showCorrectSentence ? exerciseData?.exercise?.sentence : null}
                    />
                </div>

                <div className="flex-grow"></div>

                <div className="flex flex-col gap-3">
                    {showPronunciationExercise && 
                        <>
                            {exerciseData?.exercise?.hint ?
                                <p className="text-[14px]">
                                    <span className="font-bold">{strings.speaking_lesson.speaking_lab.sentence_hint}:{" "}</span>
                                    {exerciseData?.exercise?.hint}
                                </p>
                                : <p className="text-[14px]">
                                    {exerciseData?.exercise?.sentenceTranslation}
                                  </p>
                            }
                        </>
                    }
                    {(isFirstRecordingCompleted && !showAnimation) && 
                        <SpeakingLabPlaybackOptions
                            systemPronunceButtonClick={() => speakSentence(
                                exerciseData?.exercise?.sentence, 
                                process.env.REACT_APP_LANG_EN_CODE, 
                                exerciseData?.exercise?.sentenceId
                            )}
                            memberAudioUrl={speechStudioResult?.audioUrl}
                            audioKey={audioKey}
                        />
                    }
                    <div className="flex gap-2 justify-center items-center mb-2">
                        <div className="relative group">
                            <button
                                type="button"
                                className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full", {
                                    "opacity-70 pointer-events-none" : !isSpeakingComplete,
                                    "hover:bg-nav-button-bg/70" : isSpeakingComplete
                                })}
                                disabled={!isSpeakingComplete}
                                onClick={() => {
                                    stopRecording();
                                    speakSentence(ttsInfo.sentence, ttsInfo.language, ttsInfo.textId);
                                    analytics.useAnalytics(EventLogs.SPEAKING_LAB_VOICE_AGAIN_CLICKED);
                                }}
                            >
                                <span className="material-symbols-outlined text-base-text text-[26px]">replay</span>
                            </button>
                            {isSpeakingComplete &&
                                <div className="tooltip-top -top-[38px]">
                                    {strings.speaking_lesson.speaking_lab.voice_again}
                                </div>
                            }
                        </div>
                        {showPronunciationExercise && 
                            <div className={classNames("relative group", {
                                "group-active": showRecordingTooltip
                            })}>
                                <button
                                    type="button"
                                    className={classNames("flex items-center justify-center h-14 w-14 bg-nav-button-bg rounded-full hover:bg-nav-button-bg/70", {
                                        "opacity-70 pointer-events-none" : speechStudioLoading || !isSpeakingComplete,
                                    })}
                                    disabled={speechStudioLoading || !isSpeakingComplete}
                                    onClick={() => handlePronunciation(exerciseData.exercise?.sentence)}
                                >
                                    <span className="material-symbols-outlined text-base-text text-[28px]">mic</span>
                                    {showAnimation ? (
                                        <div className="absolute inset-0 rounded-full overflow-hidden  animate-overlay">
                                            <Lottie 
                                                animationData={defaultOptions.animationData} 
                                                loop={true}
                                                autoplay={true}
                                                style={{ height: 56, width: 56 }}
                                            />
                                        </div>
                                    ) : null}
                                </button>
                                {showRecordingTooltip ? (
                                    <div className="tooltip-top -top-[58px]">
                                        {strings.speaking_lesson.speaking_lab.are_you_there}
                                    </div>
                                ) : (
                                    isSpeakingComplete && 
                                        <div className="tooltip-top -top-[38px]">
                                            {strings.video_practice.word_card.word_pronunciation}
                                        </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>

            {speechStudioLoading && <Loading text={strings.speaking_lesson.speaking_lab.results_loading} />}

            {speechStudioResult && !speechStudioLoading &&
                <div className="flex items-center justify-center">
                    <ScoreCard result={speechStudioResult} classnames="mt-5 max-w-[500px]"/>
                </div>
            }

            {(isDraggedSentenceCorrect === null && !showPronunciationExercise && hasSelectedWords) && 
                <div className='btn-outer-div my-5'>
                    <button
                        type="button"
                        className="button primary-button max-w-[330px]"
                        onClick={() => {
                            checkSentence();
                            analytics.useAnalytics(EventLogs.SPEAKING_LAB_CREATE_SENTENCE_CHECK_CLICK);
                        }}
                    >
                        {strings.speaking_lesson.quiz.control}
                    </button>
                </div>
            }

            <>
                {isMobile && <div className="h-[180px]"></div>}
                {renderResult()}
            </>
            
        </>
    );
};

export default SpeakingLabCreateSentence;