import { initializeApp } from "firebase/app";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import {useEffect, useState, createContext} from "react";

const FirebaseContext = createContext(null)
export { FirebaseContext }

const FirebaseProvider = ({ children }) => {

    const [exerciseType, setExerciseType] = useState(null);
    const [campaignStickyBar, setCampaignStickyBar] = useState(null);
    const [attendedLessonStickyBar, setAttendedLessonStickyBar] = useState(null);
    const [yearInReviewCard, setYearInReviewCard] = useState(null);

    const firebaseConfig = {
        apiKey: "AIzaSyDnuro5WLRnMPDbhnNORbldI8SRXJnEiYM",
        authDomain: "konusarakogren-20a3b.firebaseapp.com",
        projectId: "konusarakogren-20a3b",
        storageBucket: "konusarakogren-20a3b.appspot.com",
        messagingSenderId: "980548319945",
        appId: "1:980548319945:web:472355b5c95017a1cbeb18",
        measurementId: "G-DC080S81JP"
    };

    const firebase = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(firebase);

    // Firebase'den gelen verinin cache süresini 20 saniye yapmak için kullanılır
    // Yani 20 saniyede bir remoteConfig'den gelen verinin cache'i temizleniyor
    remoteConfig.settings.minimumFetchIntervalMillis = 20000;

    useEffect(() => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                let tmpExerciseType = parseInt(getValue(remoteConfig, 'exercise_type')?._value)

                let tmpCampaignStickyBar;
                try {
                    tmpCampaignStickyBar = JSON.parse(getValue(remoteConfig, 'campaign_sticky_bar')?._value);
                } catch (e) {
                    tmpCampaignStickyBar = null;
                }

                let tmpAttendedLessonStickyBar;
                try {
                    tmpAttendedLessonStickyBar = JSON.parse(getValue(remoteConfig, 'attended_lesson_sticky_bar')?._value);
                } catch (e) {
                    tmpAttendedLessonStickyBar = null;
                }

                let tmpYearInReviewCard;
                try {
                    tmpYearInReviewCard = JSON.parse(getValue(remoteConfig, 'yearly_summary')?._value);
                } catch (e) {
                    tmpYearInReviewCard = null;
                }

                setExerciseType(tmpExerciseType);
                setCampaignStickyBar(tmpCampaignStickyBar);
                setAttendedLessonStickyBar(tmpAttendedLessonStickyBar);
                setYearInReviewCard(tmpYearInReviewCard);
            })
            .catch((error) => {
                console.error('Error fetching and activating Remote Config', error);
            });
    }, [])

    const contextValue = {
        firebase: firebase,
        exerciseType: exerciseType,
        campaignStickyBar: campaignStickyBar,
        attendedLessonStickyBar: attendedLessonStickyBar,
        yearInReviewCard: yearInReviewCard
    };

    return (
        <FirebaseContext.Provider value={contextValue}>
            {children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider